import React from "react";

//Components
import Signup from "./../../components/SignUp";

const Registration = (props) => {
  return (
    <div>
      <div>
        <Signup />
      </div>
    </div>
  );
};

export default Registration;
