import React from "react";

//Components
import ProductCard from "./../../components/ProductCard";

const ProductDetails = () => {
  return (
    <div>
      <ProductCard />
    </div>
  );
};

export default ProductDetails;
