import React from "react";
import "./styles.scss";

//Components
import SignIn from "./../../components/SignIn";

const Login = (props) => {
  return (
    <div>
      <div>
        <SignIn />
      </div>
    </div>
  );
};

export default Login;
